<!-- =========================================================================================
  File Name: AddNewDataSidebar.vue
  Description: Add New Data - Sidebar component
  ----------------------------------------------------------------------------------------
  Item Name: Galileo Computing VueJS Dashboard
  Author: Ian Kibet
  Author URL: http://ian.galileoc.co.ke
========================================================================================== -->

<template>
  <vs-sidebar
    v-model="isSidebarActiveLocal"
    click-not-close
    position-right
    parent="body"
    default-index="1"
    color="primary"
    class="add-new-data-sidebar items-no-padding"
    spacer
  >
    <div class="mt-6 flex items-center justify-between px-6">
      <h4>
        {{ Object.entries(this.data).length === 0 ? 'ADD NEW' : 'UPDATE' }}
        CUSTOMER
      </h4>
      <feather-icon
        icon="XIcon"
        class="cursor-pointer"
        @click.stop="isSidebarActiveLocal = false"
      />
    </div>
    <vs-divider class="mb-0" />

    <VuePerfectScrollbar
      :settings="settings"
      :key="$vs.rtl"
      class="scroll-area--data-list-add-new"
    >
      <div class="p-6">
        <vs-input
          v-validate="'required'"
          v-model="name"
          label="Customer Name"
          class="mt-5 w-full"
          name="name"
        />
        <span v-show="errors.has('name')" class="text-danger text-sm">{{
          errors.first('name')
        }}</span>

        <vs-input
          v-validate="'required|max:10|min:10'"
          v-model="phone"
          label="Phone"
          class="mt-5 w-full"
          name="phone"
        />
        <span v-show="errors.has('phone')" class="text-danger text-sm">{{
          errors.first('phone')
        }}</span>

        <vs-textarea v-model="address" label="Address" class="mt-5 w-full" />

        <vs-input
          v-model="city"
          label="City or Town"
          class="mt-5 w-full"
          name="city"
        />

        <vs-checkbox v-model="wholesale" class="mt-5">
          <span>Wholesale</span></vs-checkbox
        >
      </div>
    </VuePerfectScrollbar>

    <div slot="footer" class="flex flex-wrap items-center p-6">
      <vs-button :disabled="!isFormValid" class="mr-6" @click="submitData"
        >Submit</vs-button
      >
      <vs-button
        type="border"
        color="danger"
        @click="isSidebarActiveLocal = false"
        >Cancel</vs-button
      >
    </div>
  </vs-sidebar>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import vSelect from 'vue-select';

export default {
  components: {
    VuePerfectScrollbar,
    'v-select': vSelect,
  },
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      id: null,
      rev: null,
      name: '',
      city: '',
      phone: '',
      address: '',
      wholesale: false,
      settings: {
        // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: 0.6,
      },
    };
  },
  computed: {
    isSidebarActiveLocal: {
      get() {
        return this.isSidebarActive;
      },
      set(val) {
        if (!val) {
          this.$emit('closeSidebar');
          // this.$validator.reset()
          // this.initValues()
        }
      },
    },
    isFormValid() {
      return !this.errors.any() && this.name && this.phone;
    },
  },
  watch: {
    isSidebarActive(val) {
      if (!val) return;
      if (Object.entries(this.data).length === 0) {
        this.initValues();
        this.$validator.reset();
      } else {
        let { _id, _rev, name, city, phone, address, wholesale } = JSON.parse(
          JSON.stringify(this.data),
        );
        this.id = _id;
        this.rev = _rev;
        this.name = name;
        this.city = city;
        this.phone = phone;
        this.address = address;
        if (typeof wholesale != 'undefined') {
          this.wholesale = wholesale;
        } else {
          this.wholesale = false;
        }
        this.initValues();
      }
    },
  },
  methods: {
    initValues() {
      if (this.data._id) return;
      this.id = null;
      this.rev = null;
      this.name = '';
      this.phone = '';
      this.address = '';
      this.wholesale = false;
    },
    submitData() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$vs.notify({
            color: 'warning',
            title: 'Processing',
            text: 'Processing Data',
          });

          const obj = {
            _id: this.id,
            _rev: this.rev,
            name: this.name,
            city: this.city,
            phone: this.phone,
            address: this.address,
            wholesale: this.wholesale,
          };

          if (this.id !== null && this.rev != null) {
            this.$store
              .dispatch('customers/updateCustomer', obj)
              .then(() => {
                this.$vs.notify({
                  color: 'success',
                  title: 'Customer Updated Successfully',
                  text: "You have successfully updated a customer's data",
                });
                this.$emit('closeSidebar');
                this.initValues();
              })
              .catch((err) => {
                this.$vs.notify({
                  color: 'danger',
                  title: 'Error',
                  text: err,
                });
                console.error(err);
              });
          } else {
            delete obj._id;
            delete obj._rev;
            this.$store
              .dispatch('customers/addCustomer', obj)
              .then(() => {
                this.$vs.notify({
                  color: 'success',
                  title: 'Customer Added Successfully',
                  text: 'You have successfully added a new customer',
                });
                this.$emit('closeSidebar');
                this.initValues();
              })
              .catch((err) => {
                this.$vs.notify({
                  color: 'danger',
                  title: 'Error',
                  text: err,
                });
                console.error(err);
              });
          }
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 400px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
  // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
  height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);
}
</style>
